<style src="../css/style.css"></style>

<template>
  <v-container class="container">
    <v-container class="content">
      <v-row>
        <v-container class="mb-5">
          <v-row class="mb-4" style="margin-bottom: 16px !important">
            <h2>Berechnen Sie Ihren Strompreis für 2025</h2>
          </v-row>
          <v-row>
            <v-col class="min_witdth" style="margin-bottom: 16px">
              <v-row class="mb-4">
                <h4>Ihre Haushaltgrösse</h4>
              </v-row>

              <v-row>
                <div>
                  <!-- Icons above the slider -->
                  <div>
                    <v-row>
                      <v-col @click="sliderValue = 0" cols="auto">
                        <v-img class="svgimage" :src="img.User1"></v-img>
                      </v-col>
                      <v-col
                        @click="sliderValue = 1"
                        cols="auto"
                        class="icon mr-1"
                      >
                        <v-img class="svgimage" :src="img.User2"></v-img>
                      </v-col>
                      <v-col
                        @click="sliderValue = 2"
                        cols="auto"
                        class="icon mr-1"
                      >
                        <v-img class="svgimage" :src="img.User3"></v-img>
                      </v-col>
                      <v-col
                        @click="sliderValue = 3"
                        cols="auto"
                        class="icon mr-1"
                      >
                        <v-img class="svgimage" :src="img.User4"></v-img>
                      </v-col>
                      <v-col
                        @click="sliderValue = 4"
                        cols="auto"
                        class="icon mr-1"
                      >
                        <v-img class="svgimage" :src="img.User5"></v-img>
                      </v-col>
                      <v-col
                        @click="sliderValue = 5"
                        cols="auto"
                        class="icon mr-1"
                      >
                        <v-img class="svgimage" :src="img.Charger"></v-img>
                      </v-col>
                      <v-col
                        @click="sliderValue = 6"
                        cols="auto"
                        class="icon mr-1"
                      >
                        <v-img class="svgimage" :src="img.Boiler"></v-img>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="mr-2">
                        <v-slider
                          v-model="sliderValue"
                          thumb-color="#008BCA"
                          track-color="#d8d4d4"
                          elevation="0"
                          track-fill-color="#d8d4d4"
                          track-size="10wpx"
                          :max="6"
                          step="1"
                        ></v-slider>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- The Slider -->

                  <!-- Show the current value of the Slider -->
                  <h6 class="center">{{ selectedElectricityDelivery.name }}</h6>
                </div>
              </v-row>
            </v-col>
            <v-col class="min_witdth">
              <v-row class="mb-4">
                <h4>Oder Ihr Stromverbrauch pro Jahr</h4>
              </v-row>
              <div ref="scrollTarget" class="scroll-target">
                <v-row>
                  <p>
                    Wenn Sie Ihren genauen Jahresverbrauch kennen, können Sie
                    den geschätzten Wert überschreiben.
                  </p>
                </v-row>
              </div>

              <v-row>
                <p style="margin-bottom: 10px; margin-top: 20px">
                  Ihr Jahresverbrauch
                </p>
              </v-row>

              <v-row class="mb-16">
                <input
                  type="tel"
                  @input="handleInput"
                  class="pl-2 outline"
                  v-model="dtKWH"
                />
                <h4 class="ml-3 center">kWh</h4>
              </v-row>
              <v-row>
                <v-btn
                  style="border-radius: 0"
                  :disabled="ctaColor"
                  elevation="0"
                  class="btn-blue"
                  color="#008BCA"
                  @click="updateValue"
                >
                  <div style="color: white">{{ buttonClick }}</div>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <v-row v-if="updateData">
        <v-row>
          <v-col class="min_witdth">
            <v-row>
              <v-col>
                <h4>Kosten 2024</h4>
                <p>inkl. 8.1% MWST</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card elevation="4">
                  <v-card-title>
                    {{ formatNumber(totalPerYear24) }}
                    <div class="unit">CHF / Jahr</div>
                  </v-card-title>
                  <v-card-title class="textmonth">
                    {{ formatNumber(totalPerMonth24) }} CHF /
                    Monat</v-card-title
                  >
                  <v-card-text>
                    <v-divider></v-divider>

                    <v-row @click="flapEnergy24">
                      <v-col cols="6" class="align-content-start">
                        <h5>Energielieferung</h5>
                      </v-col>
                      <v-col class="align-content-end icon-end">
                        <h5>{{ formatNumber(energySupply24) }} CHF</h5>
                        <button class="btn">
                          <v-icon>
                            {{ iconChevronE24 }}
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupEnergy24">
                      <v-col cols="8" class="align-content-start pt-0">
                        <p>Mixstrom (Hochtarif)</p>
                      </v-col>
                      <v-col class="align-content-end pt-0">
                        <p>{{ formatNumber(highMixCost24) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupEnergy24">
                      <v-col cols="8" class="align-content-start pt-0">
                        <p>Mixstrom (Niedertarif)</p>
                      </v-col>
                      <v-col class="align-content-end" pt-0>
                        <p>{{ formatNumber(lowMixCost24) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupEnergy24">
                      <v-col class="align-content-start">
                        <p>Grundtarif</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(baseCostMix24FR) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>

                    <v-row @click="flapNetwork24">
                      <v-col class="align-content-start">
                        <h5>Netznutzung</h5>
                      </v-col>
                      <v-col class="align-content-end icon-end">
                        <h5>{{ formatNumber(networkUsage24) }} CHF</h5>
                        <button class="btn">
                          <v-icon>
                            {{ iconChevronN24 }}
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>

                    <v-row v-if="flapupNetwork24">
                      <v-col cols="8" class="align-content-start">
                        <p>Netz 400F (Einheitstarif)</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(highNetCost24 + lowNetCost24) }} CHF</p>
                      </v-col>
                    </v-row>

                    <!--
                    <v-row v-if="flapupNetwork24">
                      <v-col cols="8" class="align-content-start">
                        <p>Netz 400F (Nieder- bzw. Einheitstarif)</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(lowNetCost24) }} CHF</p>
                      </v-col>
                    </v-row>
                    -->

                    <v-row v-if="flapupNetwork24">
                      <v-col class="align-content-start">
                        <p>Grundtarif</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(baseCostNet24FR) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupNetwork24">
                      <v-col cols="8" class="align-content-start">
                        <p>Systemdienstleistungen (SDL)</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(systemLDCost24) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupNetwork24">
                      <v-col class="align-content-start">
                        <p>Stromreserve</p>

                        <button size="20px" class="btn-start ml-3" icon>
                          <v-icon> mdi-information </v-icon>
                          <v-tooltip activator="parent" location="end"
                          >besteht aus der Wasserkraftreserve und der
                            ergänzenden Reserve</v-tooltip
                          >
                        </button>
                      </v-col>

                      <v-col class="align-content-end">
                        <p>{{ formatNumber(powerReserveCost24) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>

                    <v-row @click="flapTaxes24">
                      <v-col class="align-content-start">
                        <h5>Abgaben</h5>
                      </v-col>
                      <v-col class="align-content-end icon-end">
                        <h5>{{ formatNumber(taxes24) }} CHF</h5>
                        <button class="btn">
                          <v-icon class="btn">
                            {{ iconChevronA24 }}
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>

                    <v-row v-if="flapupTaxes24">
                      <v-col cols="8" class="align-content-start">
                        <p>Förderung Energieeffizienz</p>
                      </v-col>
                      <v-col cols="4" class="align-content-end">
                        <p>
                          {{ formatNumber(energyEfficiencyPromoCost24) }}
                          CHF
                        </p>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupTaxes24">
                      <v-col class="align-content-start">
                        <p>Bundesabgaben</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(federalTaxesCost24) }} CHF</p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="min_witdth">
            <v-row>
              <v-col>
                <h4 style="color: #0b2951">Kosten 2025</h4>
                <p>inkl. 8.1% MWST</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card elevation="4">
                  <v-card-title>
                    {{ formatNumber(totalPerYear25) }}
                    <div class="unit">CHF / Jahr</div>
                  </v-card-title>
                  <v-card-title class="textmonth">
                    {{ formatNumber(totalPerMonth25) }} CHF /
                    Monat</v-card-title
                  >

                  <v-card-text>
                    <v-divider></v-divider>

                    <v-row @click="flapEnergy25">
                      <v-col class="align-content-start">
                        <h5>Energielieferung</h5>
                      </v-col>
                      <v-col class="align-content-end icon-end">
                        <h5>{{ formatNumber(energySupply25) }} CHF</h5>
                        <button class="btn">
                          <v-icon>
                            {{ iconChevronE25 }}
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupEnergy25">
                      <v-col cols="8" class="align-content-start">
                        <p>Mixstrom (Hochtarif)</p>
                      </v-col>
                      <v-col cols="4" class="align-content-end">
                        <p>{{ formatNumber(highMixCost25) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupEnergy25">
                      <v-col cols="8" class="align-content-start">
                        <p>Mixstrom (Niedertarif)</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(lowMixCost25) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupEnergy25">
                      <v-col class="align-content-start">
                        <p>Grundtarif</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(baseCostMix25FR) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row @click="flapNetwork25">
                      <v-col class="align-content-start">
                        <h5>Netznutzung</h5>
                      </v-col>
                      <v-col class="align-content-end icon-end">
                        <h5>{{ formatNumber(networkUsage25) }} CHF</h5>
                        <button class="btn">
                          <v-icon>
                            {{ iconChevronN25 }}
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>

                    <v-row v-if="flapupNetwork25">
                      <v-col cols="8" class="align-content-start">
                        <p>Netz 400F (Einheitstarif)</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(highNetCost25+lowNetCost25) }} CHF</p>
                      </v-col>
                    </v-row>

                    <!--
                    <v-row v-if="flapupNetwork25">
                      <v-col cols="8" class="align-content-start">
                        <p>Netz 400F (Nieder- bzw. Einheitstarif)</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(lowNetCost25) }} CHF</p>
                      </v-col>
                    </v-row>

                    -->
                    <v-row v-if="flapupNetwork25">
                      <v-col class="align-content-start">
                        <p>Grundtarif</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(baseCostNet25FR) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupNetwork25">
                      <v-col cols="8" class="align-content-start">
                        <p>Systemdienstleistungen (SDL)</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(systemLDCost25) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupNetwork25">
                      <v-col class="align-content-start">
                        <p>Stromreserve</p>

                        <button size="20px" class="btn-start ml-3" icon>
                          <v-icon> mdi-information </v-icon>
                          <v-tooltip activator="parent" location="end"
                          >besteht aus der Wasserkraftreserve und der
                            ergänzenden Reserve</v-tooltip
                          >
                        </button>
                      </v-col>

                      <v-col class="align-content-end">
                        <p>{{ formatNumber(powerReserveCost25) }} CHF</p>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row @click="flapTaxes25">
                      <v-col class="align-content-start">
                        <h5>Abgaben</h5>
                      </v-col>
                      <v-col class="align-content-end icon-end">
                        <h5>{{ formatNumber(taxes25) }} CHF</h5>
                        <button class="btn">
                          <v-icon>
                            {{ iconChevronA25 }}
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>

                    <v-row v-if="flapupTaxes25">
                      <v-col cols="8" class="align-content-start">
                        <p>Förderung Energieeffizienz</p>
                      </v-col>
                      <v-col cols="4" class="align-content-end">
                        <p>
                          {{ formatNumber(energyEfficiencyPromoCost25) }}
                          CHF
                        </p>
                      </v-col>
                    </v-row>
                    <v-row v-if="flapupTaxes25">
                      <v-col class="align-content-start">
                        <p>Bundesabgaben</p>
                      </v-col>
                      <v-col class="align-content-end">
                        <p>{{ formatNumber(federalTaxesCost25) }} CHF</p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </v-container>
</template>

<script setup>
import { nextTick, onMounted, ref, watch } from "vue";
import electricityDeliverys from "@/data/electricityValues.json";

const event = new Event("Customresize");

let sliderValue = ref(2);
const electricityDelivery = electricityDeliverys;
let selectedElectricityDelivery = ref(structuredClone(electricityDelivery[2]));
let electricityDeliveryCost = ref(structuredClone(electricityDelivery[7]));
let dtKWH = ref(structuredClone(selectedElectricityDelivery.value.dtKWH));
let ltKWH = 0;
let htKWH = 0;
const scrollTarget = ref(null);

let buttonClick = ref("Berechnen");
let updateData = ref(false);
let highMixCost24 = ref();
let lowMixCost24 = ref();
let baseCostMix24FR = ref();
let highNetCost24 = ref();
let lowNetCost24 = ref();
let baseCostNet24FR = ref();
let systemLDCost24 = ref();
let powerReserveCost24 = ref();
let energyEfficiencyPromoCost24 = ref();
let federalTaxesCost24 = ref();
let totalPerYear24 = ref();
let totalPerMonth24 = ref();
let energySupply24 = ref();
let networkUsage24 = ref();
let taxes24 = ref();

let highMixCost25 = ref();
let lowMixCost25 = ref();
let baseCostMix25FR = ref();
let highNetCost25 = ref();
let lowNetCost25 = ref();
let baseCostNet25FR = ref();
let systemLDCost25 = ref();
let powerReserveCost25 = ref();
let energyEfficiencyPromoCost25 = ref();
let federalTaxesCost25 = ref();
let totalPerYear25 = ref();
let totalPerMonth25 = ref();
let energySupply25 = ref();
let networkUsage25 = ref();
let taxes25 = ref();

let flapupEnergy24 = ref(false);
let flapupNetwork24 = ref(false);
let flapupTaxes24 = ref(false);
let flapupEnergy25 = ref(false);
let flapupNetwork25 = ref(false);
let flapupTaxes25 = ref(false);

let iconChevronE24 = ref("mdi-chevron-down");
let iconChevronN24 = ref("mdi-chevron-down");
let iconChevronA24 = ref("mdi-chevron-down");
let iconChevronE25 = ref("mdi-chevron-down");
let iconChevronN25 = ref("mdi-chevron-down");
let iconChevronA25 = ref("mdi-chevron-down");

let ctaColor = ref(false);

const img = {
  User1: require("@/assets/img/User_Fill.svg"),
  User2: require("@/assets/img/User_Fill.svg"),
  User3: require("@/assets/img/User_Fill.svg"),
  User4: require("@/assets/img/User_Void.svg"),
  User5: require("@/assets/img/Home_Void.svg"),
  Charger: require("@/assets/img/Charger_Void.svg"),
  Boiler: require("@/assets/img/Boiler_Void.svg"),
};

async function updateValue() {
  Object.values(electricityDelivery).some((electricityDeliver) => {
    if (selectedElectricityDelivery.value.dtKWH !== electricityDeliver.dtKWH) {
      selectedElectricityDelivery.value.htKWH = structuredClone(
        selectedElectricityDelivery.value.dtKWH * 0.4,
      );
      selectedElectricityDelivery.value.ltKWH = structuredClone(
        selectedElectricityDelivery.value.dtKWH * 0.6,
      );
      return true;
    }

    if (selectedElectricityDelivery.value.dtKWH === electricityDeliver.dtKWH) {
      selectedElectricityDelivery.value = structuredClone(electricityDeliver);
      return true;
    }
  });
  htKWH = structuredClone(selectedElectricityDelivery.value.htKWH);
  ltKWH = structuredClone(selectedElectricityDelivery.value.ltKWH);
  highMixCost24.value = Math.round(
    selectedElectricityDelivery.value.htKWH *
      electricityDeliveryCost.value.year24.htMixCost,
  );
  lowMixCost24.value = Math.round(
    selectedElectricityDelivery.value.ltKWH *
      electricityDeliveryCost.value.year24.ltMixCost,
  );
  baseCostMix24FR.value = Math.round(
    electricityDeliveryCost.value.year24.baseCostMixFR,
  );
  highNetCost24.value = Math.round(
    selectedElectricityDelivery.value.htKWH *
      electricityDeliveryCost.value.year24.htNetCost,
  );
  lowNetCost24.value = Math.round(
    selectedElectricityDelivery.value.ltKWH *
      electricityDeliveryCost.value.year24.ltNetCost,
  );
  baseCostNet24FR.value = Math.round(
    electricityDeliveryCost.value.year24.baseCostNetFR,
  );
  systemLDCost24.value = Math.round(
    selectedElectricityDelivery.value.dtKWH *
      electricityDeliveryCost.value.year24.systemLDCost,
  );
  powerReserveCost24.value = Math.round(
    selectedElectricityDelivery.value.dtKWH *
      electricityDeliveryCost.value.year24.powerReserveCost,
  );
  energyEfficiencyPromoCost24.value = Math.round(
    selectedElectricityDelivery.value.dtKWH *
      electricityDeliveryCost.value.year24.energyEfficiencyPromoCost,
  );
  federalTaxesCost24.value = Math.round(
    selectedElectricityDelivery.value.dtKWH *
      electricityDeliveryCost.value.year24.federalTaxesCost,
  );
  energySupply24.value =
    highMixCost24.value + lowMixCost24.value + baseCostMix24FR.value;
  networkUsage24.value =
    highNetCost24.value +
    lowNetCost24.value +
    baseCostNet24FR.value +
      powerReserveCost24.value+
  systemLDCost24.value;

  taxes24.value = energyEfficiencyPromoCost24.value + federalTaxesCost24.value;

  totalPerYear24.value =
    energySupply24.value + networkUsage24.value + taxes24.value;
  totalPerMonth24.value = Math.round(totalPerYear24.value / 12);

  highMixCost25.value = Math.round(
    selectedElectricityDelivery.value.htKWH *
      electricityDeliveryCost.value.year25.htMixCost,
  );
  lowMixCost25.value = Math.round(
    selectedElectricityDelivery.value.ltKWH *
      electricityDeliveryCost.value.year25.ltMixCost,
  );
  baseCostMix25FR.value = Math.round(
    electricityDeliveryCost.value.year25.baseCostMixFR,
  );
  highNetCost25.value = Math.round(
    selectedElectricityDelivery.value.htKWH *
      electricityDeliveryCost.value.year25.htNetCost,
  );
  lowNetCost25.value = Math.round(
    selectedElectricityDelivery.value.ltKWH *
      electricityDeliveryCost.value.year25.ltNetCost,
  );
  baseCostNet25FR.value = Math.round(
    electricityDeliveryCost.value.year25.baseCostNetFR,
  );
  systemLDCost25.value = Math.round(
    selectedElectricityDelivery.value.dtKWH *
      electricityDeliveryCost.value.year25.systemLDCost,
  );
  powerReserveCost25.value = Math.round(
    selectedElectricityDelivery.value.dtKWH *
      electricityDeliveryCost.value.year25.powerReserveCost,
  );
  energyEfficiencyPromoCost25.value = Math.round(
    selectedElectricityDelivery.value.dtKWH *
      electricityDeliveryCost.value.year25.energyEfficiencyPromoCost,
  );
  federalTaxesCost25.value = Math.round(
    selectedElectricityDelivery.value.dtKWH *
      electricityDeliveryCost.value.year25.federalTaxesCost,
  );
  energySupply25.value =
    highMixCost25.value + lowMixCost25.value + baseCostMix25FR.value;
  networkUsage25.value =
    highNetCost25.value +
    lowNetCost25.value +
    baseCostNet25FR.value +
    systemLDCost25.value +
    powerReserveCost25.value;
  taxes25.value = energyEfficiencyPromoCost25.value + federalTaxesCost25.value;

  totalPerYear25.value =
    energySupply25.value + networkUsage25.value + taxes25.value;
  totalPerMonth25.value = Math.round(totalPerYear25.value / 12);

  updateData.value = true;
  buttonClick.value = "Aktualisieren";
  ctaColor.value = true;

  if (updateData.value) {
    await nextTick();
    if (scrollTarget.value) {
      scrollTarget.value.scrollIntoView({ behavior: "smooth" });
    }
  }
  window.dispatchEvent(event);
}

function flapEnergy24() {
  flapupEnergy24.value = !flapupEnergy24.value;

  if (flapupEnergy24.value === true) {
    iconChevronE24 = "mdi-chevron-up";
  }
  if (flapupEnergy24.value === false) {
    iconChevronE24 = "mdi-chevron-down";
  }
  window.dispatchEvent(event);
}
function flapNetwork24() {
  flapupNetwork24.value = !flapupNetwork24.value;
  if (flapupNetwork24.value === true) {
    iconChevronN24 = "mdi-chevron-up";
  }
  if (flapupNetwork24.value === false) {
    iconChevronN24 = "mdi-chevron-down";
  }
  window.dispatchEvent(event);
}
function flapTaxes24() {
  flapupTaxes24.value = !flapupTaxes24.value;
  if (flapupTaxes24.value === true) {
    iconChevronA24 = "mdi-chevron-up";
  }
  if (flapupTaxes24.value === false) {
    iconChevronA24 = "mdi-chevron-down";
  }
  window.dispatchEvent(event);
}
function flapEnergy25() {
  flapupEnergy25.value = !flapupEnergy25.value;
  if (flapupEnergy25.value === true) {
    iconChevronE25 = "mdi-chevron-up";
  }
  if (flapupEnergy25.value === false) {
    iconChevronE25 = "mdi-chevron-down";
  }
  window.dispatchEvent(event);
}
function flapNetwork25() {
  flapupNetwork25.value = !flapupNetwork25.value;
  if (flapupNetwork25.value === true) {
    iconChevronN25 = "mdi-chevron-up";
  }
  if (flapupNetwork25.value === false) {
    iconChevronN25 = "mdi-chevron-down";
  }
  window.dispatchEvent(event);
}
function flapTaxes25() {
  flapupTaxes25.value = !flapupTaxes25.value;
  if (flapupTaxes25.value === true) {
    iconChevronA25 = "mdi-chevron-up";
  }
  if (flapupTaxes25.value === false) {
    iconChevronA25 = "mdi-chevron-down";
  }
  window.dispatchEvent(event);
}

const handleInput = () => {
  dtKWH.value = dtKWH.value.replace(/\D/g, ""); // Entferne nicht-numerische Zeichen

  const numericValue = parseInt(dtKWH.value, 10); // Konvertiere zu einer Zahl
  if (!isNaN(numericValue)) {
    dtKWH.value = Math.min(numericValue, 100000).toString(); // Begrenze auf 100000
  }
};
const formatNumber = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
};
watch(dtKWH, () => {
  if (parseFloat(htKWH) + parseFloat(ltKWH) !== parseFloat(dtKWH.value)) {
    selectedElectricityDelivery.value.dtKWH = dtKWH.value;
    ctaColor.value = false;
  } else {
    ctaColor.value = true;
  }
});
onMounted(() => {
  window.dispatchEvent(event);
});

watch(sliderValue, () => {
  selectedElectricityDelivery.value = structuredClone(
    electricityDelivery[sliderValue.value],
  );

  dtKWH.value = selectedElectricityDelivery.value.dtKWH;

  Object.values(img).some((imageName, index) => {
    if (sliderValue.value >= index && sliderValue.value) {
      if (imageName.includes("User")) {
        img[Object.keys(img)[index]] = require("@/assets/img/User_Fill.svg");
      }
      if (imageName.includes("Charger")) {
        img[Object.keys(img)[index]] = require("@/assets/img/Charger_Void.svg");
      }
      if (imageName.includes("Boiler")) {
        img[Object.keys(img)[index]] = require("@/assets/img/Boiler_Void.svg");
      }
      if (imageName.includes("Home")) {
        img[Object.keys(img)[index]] = require("@/assets/img/Home_Void.svg");
      }
    }

    if (sliderValue.value < index) {
      if (imageName.includes("Charger")) {
        img[Object.keys(img)[index]] = require("@/assets/img/Charger_Void.svg");
      }
      if (imageName.includes("User")) {
        img[Object.keys(img)[index]] = require("@/assets/img/User_Void.svg");
      }
      if (imageName.includes("Boiler")) {
        img[Object.keys(img)[index]] = require("@/assets/img/Boiler_Void.svg");
      }
      if (imageName.includes("Home")) {
        img[Object.keys(img)[index]] = require("@/assets/img/Home_Void.svg");
      }
    }
    if (sliderValue.value === 4) {
      if (imageName.includes("Home")) {
        img[Object.keys(img)[index]] = require("@/assets/img/Home_Fill.svg");
      }
      if (imageName.includes("Charger")) {
        img[Object.keys(img)[index]] = require("@/assets/img/Charger_Void.svg");
      }
      if (imageName.includes("User")) {
        img[Object.keys(img)[index]] = require("@/assets/img/User_Void.svg");
      }
    }
    if (sliderValue.value === 5) {
      if (imageName.includes("Charger")) {
        img[Object.keys(img)[index]] = require("@/assets/img/Charger_Fill.svg");
      }
      if (imageName.includes("User")) {
        img[Object.keys(img)[index]] = require("@/assets/img/User_Void.svg");
      }
    }
    if (sliderValue.value === 6) {
      if (imageName.includes("Boiler")) {
        img[Object.keys(img)[index]] = require("@/assets/img/Boiler_Fill.svg");
      }
      if (imageName.includes("User")) {
        img[Object.keys(img)[index]] = require("@/assets/img/User_Void.svg");
      }
    }
  });
});
let sliderValue1 = ref(0);

let sliderValue2 = ref(0);

let sliderValue3 = ref(0);

let changingSlider = true;

watch(sliderValue1, () => {
  if (changingSlider === true) {
    changingSlider = false;

    sliderValue2.value = (100 - sliderValue1.value) / 2;

    sliderValue3.value = (100 - sliderValue1.value) / 2;

    changingSlider = true;
  }
});

watch(sliderValue2, () => {
  if (changingSlider === true) {
    changingSlider = false;

    sliderValue1.value = (100 - sliderValue2.value) / 2;

    sliderValue3.value = (100 - sliderValue2.value) / 2;

    changingSlider = true;
  }
});

watch(sliderValue3, () => {
  if (changingSlider === true) {
    changingSlider = false;

    sliderValue1.value = (100 - sliderValue3.value) / 2;

    sliderValue2.value = (100 - sliderValue3.value) / 2;

    changingSlider = true;
  }
});
</script>
